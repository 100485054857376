<template>
  <form>
    <p>
      Click or tap the box next to the candidates you want to vote for. Click
      the 'Vote' button and then the 'Confirm' button to register your vote.
    </p>
    <p class="ep__instruction">Click to choose</p>
    <div class="ep__candidate" v-for="(candidate, index) in candidates">
      <div class="ep__mugShot">
        <img
          class="ep__mugShot__image"
          :alt="'Photo of ' + candidate.name"
          :src="url +
            candidate.photo_filename
          "
        />
      </div>
      <label
        :for="candidate.id"
        class="ep__candidate__name ep__radio__container"
        >{{ candidate.name }}
        <input
          :id="candidate.id"
          type="checkbox"
          name="ballotPaper"
          :value="candidate.id"
          class="ep__form-check-input ep__radio"
          v-model="chosenCandidatesIds"
          :disabled="checkboxDisabled(candidate.id)"
        />
        <span class="ep__fake-radio"></span>
      </label>
    </div>
    <button
      v-show="chosenCandidatesIds.length > 0"
      class="ep__btn ep__btn-secondary ep__btn-single-right"
      v-on:click.prevent="vote()"
    >
      Vote
    </button>
    <div class="ep__confirmationModal" v-if="modalShow === true">
      <div class="ep__modal-box">
        <p class="ep__modal-box__copy">You are voting for:</p>
        <div
          class="ep__candidate"
          v-for="(candidate, index) in candidatesChosen"
        >
          <div class="ep__mugShot">
            <img
              class="ep__mugShot__image"
              :alt="'Photo of ' + candidate.name"
              :src="
                 url +
                candidate.photo_filename
              "
            />
          </div>
          <p class="ep__candidate__name">{{ candidate.name }}</p>
        </div>
        <div class="ep__next-prev">
          <button
            class="ep__btn ep__btn-secondary"
            v-on:click.prevent="modalShow = false"
          >
            Cancel
          </button>
          <button
            class="ep__btn ep__btn-primary"
            v-on:click.prevent="checkForm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="js">
import axios from "axios";

export default {
  name: "FormBallotPaper",
  props: {
    candidates: Array,
    electionID: String,
    uvn: String,
  },
  data: function () {
    return {
      errors: null,
      candidatesChosen: [],
      modalShow: false,
      axiosErrors: null,
      chosenCandidatesIds: [],
      url: process.env.VUE_APP_API_STORAGE,
      apiUrl: process.env.VUE_APP_API
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      if (this.chosenCandidatesIds.length <= 2) {
        axios
          .post(
              this.apiUrl +
              'election/' +
              this.electionID +
              "/vote",
            {
              unique_voter_code: this.uvn,
              votes: [
                {
                  candidates: this.chosenCandidatesIds,
                  position: 1,
                },
              ],
            },
            { timeout: 5000 }
          )
          .then((response) => {
            console.log(response);
            this.$emit("candidateChosenID", this.candidateChosenID);

            // GA event tracking to log success
            this.$ga.event({
              eventCategory: "Success",
              eventAction: "Vote cast by user: " + this.uvn,
              eventLabel: "Election ID: " + process.env.VUE_APP_ELECTION_ID,
            });
          })
          .catch((error) => {
            if (error.response !== undefined) {
              console.log(error.response.data);
              this.axiosErrors = error.response.data.message;
            } else {
              this.axiosErrors = error.message;
            }
            // GA event tracking to log error
            this.$ga.event({
              eventCategory: "Error",
              eventAction:
                "Axios post: Error submitting ballot for user: " + this.uvnC,
              eventLabel: "Election ID: " + process.env.VUE_APP_ELECTION_ID,
            });

            alert(
              "There has been a problem processing your vote.\nError: " +
                this.axiosErrors
            );
          });
      }
    },
    checkboxDisabled: function (candidateId) {
      const numChosen = this.chosenCandidatesIds.length;
      if (numChosen < 2) {
        return false;
      }
      const candidateChosen =
        this.chosenCandidatesIds.findIndex(function (candidate) {
          return candidate === candidateId;
        }) > -1;

      return !candidateChosen;
    },
    vote: function () {
      const cands = this.chosenCandidatesIds;
      this.candidatesChosen = this.candidates.filter(function (candidate) {
        const candidateIndex = cands.findIndex(function (potentialCandidate) {
          return potentialCandidate === candidate.id;
        });
        return candidateIndex > -1;
      });
      this.modalShow = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/vars.scss";
#score-election-app {
  .ep__instruction {
    color: $color-2;
    font-weight: 700;
    margin-bottom: 0;
    text-align: right;
  }

  .ep__candidate {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1em;

    .ep__mugShot {
      flex: calc(25%);
      max-width: calc(25%);
    }
    .ep__candidate__name {
      flex: calc(75%);
      max-width: calc(75%);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
    }
  }

  .ep__btn-single-right {
    float: right;
  }

  .ep__confirmationModal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .ep__modal-box {
    max-width: 500px;
    width: calc(100vw - 30px);
    margin: 20% auto;
    background-color: white;
    padding: 20px;
    border: 1px solid $color-2;

    .ep__modal-box__copy {
      font-size: 1.2em;
      font-weight: 700;
    }
    .ep__candidate {
      .ep__mugShot {
        flex: calc(33.33333%);
        max-width: calc(33.33333%);
      }
      .ep__candidate__name {
        flex: calc(66.66667%);
        max-width: calc(66.66667%);
      }
    }
  }

  .ep__radio__container {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .ep__radio {
      opacity: 0;
    }
    .ep__fake-radio {
      position: relative;
      height: 50px;
      width: 50px;
      padding: 4px 0 0 1px;
      border: 1px solid grey;
      display: flex;
      align-items: center;
      justify-content: space-around;
      transition: background-color 0.5s ease;

      &:hover,
      &:active {
        background-color: lighten($color-2, 60%);
      }
    }
    input ~ .ep__fake-radio::after {
      content: "X";
      color: $color-2;
      font-weight: 700;
      font-size: 50px;
      opacity: 0;
      transform-origin: center;
      transform: scale(0);
      transition: transform 0.5s ease, opacity 0.5s ease;
    }
    input:checked ~ .ep__fake-radio::after {
      opacity: 1;
      transform: none;
    }
  }
}
</style>
