<template>
  <form @submit="checkCredentials">
    <p>
      Before you can cast your Ballot we have to confirm who you are. This is to
      make sure that only people eligible to vote in this election can cast a
      vote and that nobody votes more than once.
    </p>
    <div class="ep__form-group">
      <label for="uvn">Your Unique Voting Number</label>
      <input
        v-model="uvnC"
        class="ep__form-control"
        type="text"
        maxlength="10"
        id="uvn"
        @change="checkInputs(['uvnC'])"
        v-bind:class="{ ep__hasError: errors.uvnC.length }"
      />
    </div>
    <div class="ep__form-group">
      <label for="niNumber">Your National Insurance Number</label>
      <input
        v-model="nin"
        class="ep__form-control"
        type="text"
        maxlength="9"
        id="niNumber"
        @change="checkInputs(['nin'])"
        v-bind:class="{ hasError: errors.nin.length }"
      />
    </div>
    <div v-if="errors.all.length" class="ep__form-errors">
      <h2>Errors</h2>
      <ul>
        <li v-for="error in errors.all" v-bind:key="error" v-html="error"></li>
      </ul>
    </div>
    <div class="ep__next-prev">
      <router-link v-on:click.prevent to="/candidates"
        ><div role="button" class="ep__btn ep__btn-secondary">
          <img
            class="ep__icon ep__icon--reversed"
            src="@/assets/icon--go.svg"
            alt="Icon go"
          />
          Back
        </div></router-link
      >
      <button
        type="submit"
        class="ep__btn ep__btn-primary"
        :disabled="notSubmittable"
      >
        Next <img class="ep__icon" src="@/assets/icon--go.svg" alt="Icon go" />
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";

const checkLuhn = function (code) {
  var len = code.length,
    parity = len % 2,
    sum = 0;
  for (var i = len - 1; i >= 0; i--) {
    var d = parseInt(code.charAt(i));
    if (i % 2 == parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  return sum % 10 === 0 ? true : false;
};
const checkNin = function (nin) {
  var valid =
    /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/i.test(nin);
  return valid;
};

export default {
  name: "FormVerification",
  props: {
    uvn: String,
  },
  data: function () {
    return {
      errors: {
        nin: [],
        uvnC: [],
        misc: [],
        all: [],
      },
      nin: null,
      uvnC: this.uvn,
      queryStringUvn: null,
      url: process.env.VUE_APP_API_STORAGE,
      apiUrl: process.env.VUE_APP_API
    };
  },
  computed: {
    notSubmittable: function () {
      if (
        this.errors.all.length === 0 &&
        this.nin !== null &&
        this.uvnC.length > 0
      )
        return false;
      else return true;
    },
  },
  methods: {
    checkInputs: function (fields) {
      if (fields.indexOf("uvnC") !== -1) {
        this.errors.uvnC = [];
        if (this.uvnC === null || this.uvnC === "")
          this.errors.uvnC.push("Your Unique Voting Number is required");
        else if (!checkLuhn(this.uvnC))
          this.errors.uvnC.push("Your Unique Voting Number is invalid");
      }
      if (fields.indexOf("nin") !== -1) {
        this.errors.nin = [];
        if (this.nin === null || this.nin === "")
          this.errors.nin.push("Your National Insurance Number is required");
        else {
          this.nin = this.nin.toUpperCase();
          if (!checkNin(this.nin))
            this.errors.nin.push("Your National Insurance Number is invalid");
        }
      }
      this.errors.misc = [];
      this.errors.all = [];
      this.errors.all = this.errors.all.concat(this.errors.uvnC);
      this.errors.all = this.errors.all.concat(this.errors.nin);
    },
    checkCredentials: function (e) {
      e.preventDefault();
      this.checkInputs(["nin", "uvnC"]);

      if (this.errors.all.length === 0) {
        // console.log('uvn: ' + this.uvnC);
        // console.log('nin: ' + this.nin);
        // this.$emit('validated', true);
        axios
          .post(
              this.apiUrl +
              'member/' +
              this.uvnC +
              "/validate",
            {
              ni_number: this.nin,
            },
            { timeout: 5000 }
          )
          .then((response) => {
            // console.log(response);
            this.$emit("validated", true, this.uvnC);
          })
          .catch((error) => {
            // GA event tracking to log error
            this.$ga.event({
              eventCategory: "Error",
              eventAction: "Axios post: Error validating user: " + this.uvnC,
              eventLabel: "Election ID: " + process.env.VUE_APP_ELECTION_ID,
            });

            // Handle error messages
            if (error.response !== undefined) {
              this.errors.misc = [];
              if (typeof error.response.data === "string") {
                this.errors.misc.push(error.response.data);
              } else if (
                error.response.data.message &&
                error.response.data.errors
              ) {
                this.errors.misc.push(error.response.data.message);
                Object.values(error.response.data.errors).forEach(
                  (returnedError) => {
                    this.errors.misc = this.errors.misc.concat(returnedError);
                  }
                );
              }
              this.errors.all = this.errors.all.concat(this.errors.misc);
            } else {
              this.errors.all = [
                "There has been a problem connecting to the identity verification system. Please try again later.",
              ];
              console.log(error.message);
              console.log(error.code);
              console.log(error.stack);
            }
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/vars.scss";
#score-election-app {
  .ep__form-errors {
    color: darkred;
    background-color: lighten(lightpink, 10%);
    padding: 10px 10px 0;
    border: 1px solid darkred;
    margin-bottom: 15px;
    max-height: 300px;
    overflow: hidden;
  }
  .ep__form-control.ep__hasError {
    background-color: lighten(lightpink, 10%);
  }
}
</style>
