<template>
	<div class="ep__completion">
		<p>Your Ballot has been submitted and your vote has been counted.</p>
		<p>The results will be announced on <strong>before the end of February</strong>.</p>
	</div>
</template>

<script>
	export default {
		name: 'Completion',

		props: {
			resultDate: String
		}
	}
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  #score-election-app {
	strong {
		color: $color-2;
	}
  }
</style>