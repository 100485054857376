<template>
  <div class="ep__page ep__page--vote">
  	<Navigation />
    <h1>Your vote</h1>
    <hr>
    <h2>{{ pageTitle }}</h2>
    <FormVerification
      v-show="currentStep === 0"
      :uvn="preFilledUvn"
      @validated="verificationValidated"
    />
    <FormBallotPaper
      v-show="currentStep === 1"
      :candidates="electionData.candidates"
      :electionID="electionID"
      :uvn="uvn"
      @candidateChosenID="candidateChosenID"
    />
    <Completion
      v-show="currentStep === 2"
      :resultDate="electionData.result"
    />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from '@/components/Navigation.vue'
  import FormVerification from '@/components/FormVerification.vue'
  import FormBallotPaper from '@/components/FormBallotPaper.vue'
  import Completion from '@/components/Completion.vue'

  export default {
    components: {
      Navigation,
      FormVerification,
      FormBallotPaper,
      Completion
    },
    props: {
      electionData: Object
    },
    data: function () {
      return {
        // pageTitle: 'Confirm your identity',
        currentStep: 0,
        uvn: null,
        electionID: process.env.VUE_APP_ELECTION_ID
      }
    },
    methods: {
      scrollToTop () {
        window.scrollTo(0,0);
      },
      verificationValidated (value, uvnC) {
        if ( value === true ) {
          this.currentStep = 1;
          this.uvn = uvnC;
        }
        console.log(this.currentStep);
      },
      candidateChosenID (candidateID) {
        this.currentStep = 2;
      },
      getCookie (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    },
    computed: {
      pageTitle: function () {
        if ( this.currentStep === 0 ) {
          return 'Confirm your identity'
        } else if ( this.currentStep === 1 ) {
          return 'Your online ballot paper'
        } else if ( this.currentStep === 2 ) {
          return 'Thank you'
        } 
      },
      preFilledUvn: function () {
        if ( this.queryStringUvn !== null ) {
          return this.queryStringUvn;
        } else if ( this.getCookie('uvn') !== null ) {
          return this.getCookie('uvn');
        } else {
          return null
        }
      }
    },
    created() {
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      this.queryStringUvn = params.get("uvn");
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  #score-election-app {

  }
</style>
